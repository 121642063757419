<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #f0f8ff"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/comunicante/logo-wellon.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #356a7c"
          >
            Soluções inteligentes para atendimento e relacionamento com o
            cliente!
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Bem-vindo ao Wellon!
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    placeholder="Email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Esqueceu sua senha ?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="senha"
                    ref="senha"
                    placeholder="Senha"
                    v-model="form.senha"
                    autocomplete="off"
                  />
                </div>
              </div>
              <p class="text-muted">
                Este site é protegido pelo reCAPTCHA e a
                <a href="https://policies.google.com/privacy"
                  >Política de Privacidade</a
                >
                e os
                <a href="https://policies.google.com/terms"
                  >Termos de Serviço</a
                >
                do Google se aplicam.
              </p>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  alt="Matheus Rodrigues Best Developer :P"
                >
                  Entrar
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Recuperação de senha
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Informe seu email para recuperar sua senha.
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  v-model="formResetPassword.email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="submit"
                  id="kt_login_forgot_submit"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import {
  LOGIN,
  LOGOUT,
  RESET_PASSWORD
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        senha: "",
        token: ""
      },

      formResetPassword: {
        email: ""
      }
    };
  },
  computed: {
    ...mapGetters(["validatorErrors"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/comunicante/bg-login.svg";
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    this.limparCacheFiltrosCampanha();

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            emailAddress: {
              message: "Informe um email válido."
            },
            blank: {}
          }
        },
        senha: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            emailAddress: {
              message: "Informe um email válido."
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", async () => {
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      await this.$recaptchaLoaded();
      this.form.token = await this.$recaptcha("login");

      this.$store
        .dispatch(LOGIN, this.form)
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          for (const error in this.validatorErrors) {
            this.fv
              .updateValidatorOption(
                error,
                "blank",
                "message",
                this.validatorErrors[error]
              )
              .updateFieldStatus(error, "Invalid", "blank");
          }
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Verifique suas credenciais!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv1.on("core.form.valid", () => {
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(RESET_PASSWORD, this.formResetPassword)
        .then(() => {
          this.state = "signin";
          this.form = {
            email: "",
            senha: ""
          };
          this.formResetPassword = {
            email: ""
          };

          Swal.fire({
            title: "MENSAGEM: ",
            text: "Um link para recuperação de senha foi enviado para você, verifique seu email!",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false
          });
        })
        .catch(() => {
          for (const error in this.validatorErrors) {
            this.fv
              .updateValidatorOption(
                error,
                "blank",
                "message",
                this.validatorErrors[error]
              )
              .updateFieldStatus(error, "Invalid", "blank");
          }
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    limparCacheFiltrosCampanha() {
      // Clear from localStorage (filtrosCampanha)
      localStorage.removeItem("filtrosCampanha");
    }
  }
};
</script>
